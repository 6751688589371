import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Spacer,
  Stat,
  StatHelpText,
  StatNumber,
} from "@chakra-ui/react";

export default function TPForm({ products }) {
  const [selectValue, setSelectValue] = useState("");
  const [rolls, setRolls] = useState("");
  const [price, setPrice] = useState("");
  const [feetPerRoll, setFeetPerRoll] = useState("");
  const [sheetsPerRoll, setSheetsPerRoll] = useState("");

  function costPerSquareFoot() {
    const cost = (price * 100) / (feetPerRoll * rolls);
    return prettifyCost(cost);
  }

  function costPerSheet() {
    const cost = (price * 100) / (sheetsPerRoll * rolls);
    return prettifyCost(cost);
  }

  function prettifyCost(cost) {
    if (!isNaN(cost) && cost > 0 && isFinite(cost)) {
      return cost.toFixed(3) + "¢";
    }
    return "---";
  }

  function updateMeasurements(val) {
    const [product, inches, sheets] = val.split(",");

    const updatedFeet = (sheets * inches) / 144;
    setFeetPerRoll(updatedFeet);

    setSheetsPerRoll(sheets);

    setSelectValue(val);
  }

  const productList = products.map((p) => (
    <option
      value={[p.Product, p.InchesPerSheet, p.SheetsPerRoll].join()}
      key={p.Product}
    >
      {p.Product}
    </option>
  ));

  return (
    <Grid
      templateColumns={["repeat(4, 1fr)", null, "repeat(8, 1fr)"]}
      templateRows={["repeat(2, 1fr)", null, "repeat(1, 1fr)"]}
    >
      <GridItem colSpan="4">
        <Select
          placeholder="Choose toilet paper"
          onChange={(e) => updateMeasurements(e.target.value)}
          value={selectValue}
        >
          {productList}
        </Select>
      </GridItem>
      <GridItem>
        <Input
          placeholder="Rolls"
          value={rolls}
          onChange={(e) => setRolls(e.target.value)}
        />
      </GridItem>
      <GridItem>
        <InputGroup>
          <InputLeftAddon children="$" />
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </InputGroup>
      </GridItem>
      <GridItem>
        <Stat size="xs" bg="gray.50">
          <StatNumber>{costPerSquareFoot()}</StatNumber>
          <StatHelpText>/ft²</StatHelpText>
        </Stat>
      </GridItem>
      <GridItem>
        <Stat size="xs" bg="gray.100">
          <StatNumber>{costPerSheet()}</StatNumber>
          <StatHelpText>/sheet</StatHelpText>
        </Stat>
      </GridItem>
    </Grid>
  );
}
