import { Box, ListItem, UnorderedList, Text } from "@chakra-ui/react";

export default function Blurb() {
  return (
    <Box p={[5, null, 20]} rounded="lg">
      <Text>
        Use this tool to calculate and compare the cost per sheet and square
        foot of many common types of toilet paper. Simply choose a product from
        the menu, then enter the number of rolls in the package and the price. A
        few tips:
      </Text>
      <UnorderedList px="50">
        <ListItem>
          Make sure to enter the number of <strong>actual rolls</strong>.
          Manufacturers often display a "normal roll equivalent" figure more
          prominently than the number of actual "mega," "supreme," or other
          rolls included in a package.
        </ListItem>
        <ListItem>
          Cost is only one factor. Use this tool as a guide, being aware that
          characteristics like softness, strength, and absorbency vary
          enormously between types of toilet paper. Only you can say how much
          these factors are worth to you and your family.
        </ListItem>
        <ListItem>
          Bigger packages are not always better buys. You might save only a
          penny or two per roll if you buy an 18-roll package of Target's
          premium "up & up" toilet paper instead of a 6-pack, for example.
        </ListItem>
        <ListItem>
          The best way to save is to stock up when your favorites go on sale.
          Coupons often make a big difference, too. As long as you've got the
          space to store it, you can buy lots of discounted toilet paper without
          worry.
        </ListItem>
        <ListItem>
          Consider keeping track of deals. Dump the numbers from this tool into
          your own toilet paper spreadsheet, and it will be easier to tell
          whether future sales and special offers are worth getting excited
          about.
        </ListItem>
      </UnorderedList>
      Have a suggestion, bug report, or product to add?{" "}
      <a
        style={{ textDecoration: "underline" }}
        href="mailto:eastriversidesoftworks@gmail.com?subject=Toilet Paper Calculator"
      >
        Send us an email!
      </a>
    </Box>
  );
}
