import { Heading } from "@chakra-ui/react";
import "./App.css";
import TPContainer from "./TPContainer";
import Blurb from "./Blurb";

function App() {
  return (
    <div className="App">
      <Heading m="10">Toilet Paper Cost Calculator</Heading>
      <TPContainer />
      <Blurb />
    </div>
  );
}

export default App;
