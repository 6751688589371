import { useState } from "react";
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Box,
  ButtonGroup,
  Center,
  IconButton,
} from "@chakra-ui/react";
import TPForm from "./TPForm";
import products from "./tp.json";

export default function TPContainer() {
  const [calcList, setCalcList] = useState([
    <TPForm products={products} key="0" />,
    <TPForm products={products} key="1" />,
  ]);

  function removeCalc(e) {
    if (calcList.length > 1) {
      setCalcList(calcList.slice(0, -1));
    }
  }
  function addCalc(e) {
    if (calcList.length < 5) {
      const newCalcList = [
        ...calcList,
        <TPForm products={products} key="{calcList.length}" />,
      ];
      setCalcList(newCalcList);
    }
  }

  return (
    <Box>
      {calcList}
      <Center mt="3rem">
        <ButtonGroup spacing="4">
          <IconButton
            colorScheme="red"
            onClick={removeCalc}
            isDisabled={calcList.length < 2 ? "true" : ""}
            icon={<MinusIcon />}
            width="3.5rem"
          />

          <IconButton
            colorScheme="blue"
            onClick={addCalc}
            isDisabled={calcList.length > 4 ? "true" : ""}
            icon={<AddIcon />}
            width="3.5rem"
          />
        </ButtonGroup>
      </Center>
    </Box>
  );
}
